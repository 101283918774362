import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 295.000000 161.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,161.000000) scale(0.100000,-0.100000)">

<path d="M605 1557 c-226 -118 -426 -318 -539 -537 -69 -134 -69 -130 7 -130
l64 0 39 75 c101 197 262 358 499 500 13 8 16 23 13 72 l-3 62 -80 -42z"/>
<path d="M616 1312 c-128 -85 -247 -211 -329 -348 -20 -34 -37 -64 -37 -68 0
-3 30 -6 68 -6 l67 0 30 52 c31 52 184 215 243 259 33 24 33 25 30 91 l-3 67
-69 -47z"/>
<path d="M730 1220 l0 -100 63 0 c77 0 165 -22 222 -56 66 -38 150 -134 174
-199 108 -288 -28 -583 -295 -640 -44 -9 -128 -15 -231 -15 l-163 0 0 315 0
315 -110 0 -110 0 0 -415 0 -415 281 0 c154 0 313 5 352 11 259 40 452 216
514 468 22 89 22 275 0 361 -31 117 -82 207 -166 290 -121 119 -272 180 -448
180 l-83 0 0 -100z"/>
<path d="M1560 610 c0 -593 0 -600 20 -600 20 0 20 7 20 600 0 593 0 600 -20
600 -20 0 -20 -7 -20 -600z"/>
<path d="M2483 1202 c-30 -3 -33 -6 -33 -38 0 -30 -3 -34 -25 -34 -22 0 -25
-4 -25 -35 0 -31 3 -35 25 -35 25 0 25 -1 25 -85 0 -78 2 -88 25 -110 19 -20
34 -25 75 -25 55 0 69 14 51 48 -7 14 -17 18 -31 15 -37 -10 -50 14 -50 88 l0
69 40 0 c39 0 40 1 40 35 0 34 -1 35 -39 35 -39 0 -40 1 -43 38 -3 35 -5 37
-35 34z"/>
<path d="M1690 1004 l0 -166 100 4 c110 4 135 15 178 78 45 67 22 181 -47 227
-30 20 -47 23 -132 23 l-99 0 0 -166z m181 100 c38 -19 63 -75 54 -120 -9 -52
-55 -84 -121 -84 l-54 0 0 103 c0 57 3 107 7 110 12 13 84 7 114 -9z"/>
<path d="M2099 1115 c-86 -46 -90 -198 -8 -258 35 -25 116 -26 153 -2 23 16
26 16 26 1 0 -12 9 -16 35 -16 l35 0 0 145 0 145 -30 0 c-16 0 -33 -7 -38 -16
-9 -16 -11 -16 -33 0 -29 20 -104 21 -140 1z m144 -77 c27 -32 23 -92 -9 -117
-15 -12 -37 -21 -49 -21 -12 0 -34 9 -49 21 -43 34 -33 119 17 139 30 13 69 3
90 -22z"/>
<path d="M2709 1115 c-86 -46 -90 -198 -8 -258 35 -25 116 -26 153 -2 23 16
26 16 26 1 0 -12 9 -16 35 -16 l35 0 0 145 0 145 -30 0 c-16 0 -33 -7 -38 -16
-9 -16 -11 -16 -33 0 -29 20 -104 21 -140 1z m144 -77 c27 -32 23 -92 -9 -117
-15 -12 -37 -21 -49 -21 -12 0 -34 9 -49 21 -43 34 -33 119 17 139 30 13 69 3
90 -22z"/>
<path d="M615 1054 c-39 -38 -85 -90 -103 -116 l-33 -48 73 0 c73 0 73 0 106
40 32 38 33 42 30 116 l-3 77 -70 -69z"/>
<path d="M790 878 c-130 -38 -178 -186 -98 -301 45 -63 159 -88 235 -50 107
53 138 201 61 289 -52 59 -127 83 -198 62z"/>
<path d="M1956 804 c-29 -28 -12 -64 29 -64 43 0 62 50 26 70 -27 14 -37 13
-55 -6z"/>
<path d="M1740 730 c0 -36 -2 -40 -25 -40 -20 0 -25 -5 -25 -25 0 -20 5 -25
25 -25 25 0 25 -1 25 -80 0 -43 5 -90 11 -103 13 -30 48 -47 92 -47 43 0 57
11 50 38 -4 17 -13 22 -38 22 -40 0 -45 11 -45 101 l0 69 40 0 c36 0 40 3 40
25 0 23 -4 25 -40 25 l-40 0 0 40 c0 39 -1 40 -35 40 -34 0 -35 -1 -35 -40z"/>
<path d="M2197 680 c-30 -24 -37 -25 -37 -5 0 10 -10 15 -30 15 l-30 0 0 -140
0 -140 35 0 35 0 0 93 c0 85 2 96 23 115 28 27 58 28 80 4 13 -15 17 -39 17
-115 l0 -97 35 0 35 0 0 89 c0 74 3 92 21 115 23 29 55 33 84 12 16 -12 20
-30 23 -115 l4 -101 36 0 35 0 -5 108 c-6 127 -19 156 -79 174 -44 13 -116 -2
-125 -27 -4 -10 -14 -8 -41 11 -44 29 -82 31 -116 4z"/>
<path d="M2690 682 c-24 -11 -43 -32 -58 -64 -31 -62 -23 -116 23 -166 31 -34
37 -36 101 -40 57 -2 75 1 103 18 l34 21 -21 21 c-18 18 -23 19 -37 8 -9 -7
-37 -13 -62 -13 -39 0 -50 4 -71 29 l-25 29 118 3 118 3 -6 41 c-10 74 -72
128 -147 128 -19 0 -51 -8 -70 -18z m130 -62 c33 -33 24 -40 -55 -40 -79 0
-89 7 -58 42 23 25 87 24 113 -2z"/>
<path d="M1950 550 l0 -140 35 0 35 0 0 140 0 140 -35 0 -35 0 0 -140z"/>
<path d="M2380 220 l0 -200 29 0 c17 0 31 6 35 15 5 14 8 14 30 -1 56 -36 161
-12 190 44 38 75 27 151 -29 201 -29 25 -45 31 -81 31 -25 0 -58 -7 -74 -15
l-30 -16 0 71 0 70 -35 0 -35 0 0 -200z m189 15 c16 -8 31 -22 35 -30 32 -85
-59 -163 -122 -105 -44 41 -35 110 18 137 32 16 35 16 69 -2z"/>
<path d="M1761 284 c-37 -31 -42 -83 -11 -114 19 -19 29 -23 105 -38 30 -6 40
-13 40 -27 0 -16 -8 -21 -44 -23 -24 -2 -57 2 -73 9 -25 10 -31 9 -43 -7 -20
-27 -19 -32 13 -47 98 -50 222 -15 222 62 0 61 -26 83 -113 95 -78 12 -75 56
3 55 19 0 44 -4 55 -8 20 -8 44 11 45 36 0 17 -56 33 -116 33 -42 0 -59 -5
-83 -26z"/>
<path d="M2027 303 c-9 -8 -9 -135 -1 -186 4 -21 19 -51 34 -67 23 -25 36 -30
75 -30 25 0 59 7 75 15 27 14 30 14 30 0 0 -11 10 -15 35 -15 l36 0 -3 143 -3
142 -37 3 -38 3 0 -94 c0 -85 -2 -96 -23 -115 -29 -27 -61 -28 -87 -2 -17 17
-20 33 -20 115 l0 95 -33 0 c-19 0 -37 -3 -40 -7z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
